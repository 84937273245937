var wipwrp = window.wipwrp || {};

wipwrp.profilo = (function($,mzr,doc,win){

	/* ----------------- private vars ---------------*/

	var	self				=	{};

	/* ----------------- public methods ---------------*/

	self.init = function(){
		$('form#form_profilo').parsley(); 
		$('form#form_cambiapassword').parsley();
		wipwrp.commons.datepicker();
		wipwrp.commons.relationField();
		wipwrp.autocomplete.init();
		wipwrp.autocomplete.init({
			selector: ".autocomplete-prefisso",
			valueinfield: true
		});
		wipwrp.autocomplete.init({
			selector: ".autocomplete-provincia",
			valueinfield: true
		});

		wipwrp.utils.uppercaseControl('[data-textcontrol="uppercase"]');
		wipwrp.utils.lowercaseControl('[data-textcontrol="lowercase"]');
		wipwrp.utils.capitalizeControl('[data-textcontrol="capitalize"]');

		_controlChanges();
		wipwrp.commons.checkCapByNation();
		wipwrp.commons.initCkEditor('#storia_attivita');
    }

	function _controlChanges(){
		$('#Nazione').blur(function(e){_controllaNazione('Nazione_value','Prov_value','Prov','controlgroup_Prov','controlgroup_Nazione','CAP')});
		$('#NazioneSpedizione').blur(function(e){_controllaNazione('NazioneSpedizione_value','ProvSpedizione_value','ProvSpedizione','controlgroup_ProvSpedizione','controlgroup_NazioneSpedizione','CAPSpedizione')});
	}
	
	function _controllaNazione(nazione_nascosta,provincia_nascosta,provincia_visibile,controlgroup_provincia,controlgroup_nazione,cap_nazione){
		if($('#'+nazione_nascosta+'').val()!='ITA'){
			$('#'+provincia_visibile+'').removeAttr('required');
			$('#'+provincia_nascosta+'').val('');
			$('#'+controlgroup_provincia+'').hide();
			$('#'+controlgroup_nazione+'').removeClass('col_3').addClass('col_6');
			$('#'+cap_nazione+'').removeAttr('required');
			$('#'+cap_nazione+'').attr('data-parsley-maxlength','12');
			$('#'+cap_nazione+'').attr('data-parsley-pattern','/^[a-zA-Z0-9_. -]{0,12}$/');
			$('#'+cap_nazione+'').removeAttr('data-parsley-type');
		}else{
			$('#'+provincia_visibile+'').val('').attr('required','');
			$('#'+provincia_nascosta+'').val('');
			$('#'+controlgroup_provincia+'').show();
			$('#'+controlgroup_nazione+'').removeClass('col_6').addClass('col_3');
			$('#'+cap_nazione+'').attr('required',true);
			$('#'+cap_nazione+'').attr('data-parsley-minlength','5');
			$('#'+cap_nazione+'').attr('data-parsley-maxlength','5');
			$('#'+cap_nazione+'').attr('data-parsley-type','number');
		}
	}
	/* ----------------- public methods ---------------*/

	return self;

})(jQuery,Modernizr, document, window);
